<template lang="pug">
  v-btn(
    text
    :class="{'no-slots': !avaliableSlots, 'no-hold': !onHoldShowing}"
  ).show-more-btn.d-flex.justify-center.my-6 Show more ({{ hiddenItemsCount }})
</template>
<script>
export default {
  props: {
    hiddenItemsCount: Number,
    avaliableSlots: Boolean,
    onHoldShowing: Boolean,
  }
}
</script>
<style lang="scss" scoped>
.show-more-btn {
  color: $warning-color;
  cursor: pointer;
  margin-left: 25px;
  border-radius: 0;
  width: calc(100% - 105px);
  height: 40px!important;
  border-bottom: 1px dashed;
  border-top: 1px dashed;
  border-color: $label-color;
  &.no-slots.no-hold {
    width: calc(100% - 41px);
  }
  &:hover {
    color: $primary-color;
    border-color: $primary-color;
  }
}
</style>